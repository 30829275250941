import React, { useLayoutEffect } from "react";
import { 
  BrowserRouter as Router, 
  Navigate,
  Routes, 
  Route,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";

import Login from './pages/Login';
import LoginLti from './pages/LoginLti';
import Logout from './pages/Logout';
import NotAllowed from './pages/NotAllowed';
import Video from './pages/Video';
import SetupProfile from './pages/SetupProfile';
import Profile from './pages/Profile';
import Help from './pages/Help';
import Notifications from './pages/Notifications';
import Admin from './pages/Admin';
import Analytics from './pages/Analytics';
import Annotate from './pages/Annotate';

import store from './store'; 
import * as auth from "./store/auth";
import { connect, Provider } from 'react-redux';

import { validateEmail } from "./helpers/validator";

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		// "document.documentElement.scrollTo" is the magic for React Router Dom v6
		document.documentElement.scrollTo({
		  top: 0,
		  left: 0,
		  behavior: "instant", // Optional if you want to skip the scrolling animation
		});
	}, [pathname]);

	return null;
};

const ProtectedRoute = ({
		isAllowed,
		redirectPath = '/login',
		requireValidEmail,
		requireRID = false,
		authData,
		children,
	}) => {
		if (!isAllowed) {
			return <Navigate to={redirectPath} replace />;
		}

		if (
			requireValidEmail &&
			(authData.user !== null) &&
			!validateEmail(authData.user.email)
		) {
			return <Navigate to="/setup" replace />;
		}

		return children ? children : <Outlet />;
};

const NoMatch = ({ location }) => (
	<div className="text-center">
		<h1 className="mt-16 md:mt-32 p-8 text-6xl">
			Page Not Found
		</h1>
		<p className="text-lg mb-12">
			Sorry, the page you were looking for could not be found.
		</p>
		<Link to="/" className="mt-24" title="Home">
			Go to homepage
		</Link>
	</div>
);

class RootContainerComponent extends React.Component {
	componentDidMount() {
		if (this.props.auth.isLoggedIn) {
			this.props.checkLogin()
		}
	}
	
	PrivateRoute = ({ component: ChildComponent, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) => {
					if (this.props.auth.isLoading) {
					return (
						<div className="flex justify-center items-center min-h-screen pb-32">
						<em className="text-lg text-gray-800">Loading...</em>
						</div>
					);
					} else if (!this.props.auth.isAuthenticated) {
					return (
						<Navigate
						to={{
							pathname: "/login",
							state: { next: rest.location.pathname },
						}}
						/>
					);
					} else {
						return <ChildComponent {...props} />;
					}
				}}
			/>
		);
	};

	render() {
		let { PrivateRoute } = this;
		// const isLoading = this.props.auth.isLoading;
		// <Route exact path="/login/validate/:token" element={Video}/>
		// <PrivateRoute path="/profile/:userId" component={Video} />
		// <Route path='/video/:videoId' element={<Annotate/>}/>
		// <Route path='/analytics/:videoId' element={<Analytics/>}/>
		// <Route exact path='/page-not-found' element={<NoMatch/>}/>
		
		const isLoading = false;

		if (isLoading) {
		<div className="flex justify-center items-center min-h-screen pb-32">
			<em className="text-lg text-gray-800">Loading...</em>
		</div>
		}

		return (
			<Router>
				<ScrollToTop />
				<Routes>
					<Route element={
						<ProtectedRoute
							isAllowed={this.props.auth.isLoggedIn}
							requireValidEmail={true}
							authData={this.props.auth} />
					}>
						<Route index element={ <Video /> }/>

						<Route exact path="/video" element={<Video />} />
						<Route exact path="/video/:videoId" element={<Annotate />} />

						<Route path='/admin' element={ <Admin /> }/>
						<Route path='/analytics/:videoId' element={ <Analytics /> }/>
						<Route path='/notifications' element={ <Notifications /> }/>
						<Route path='/help' element={ <Help /> }/>
						<Route path='/profile' element={ <Profile /> }/>
					</Route>

					<Route element={
						<ProtectedRoute
							isAllowed={this.props.auth.isLoggedIn}
							requireValidEmail={false}
							authData={this.props.auth} />
					}>
						<Route path='/setup' element={ <SetupProfile /> }/>
					</Route>

					<Route exact path='/login' element={ <Login /> }/>
					<Route path='/login/lti' element={ <LoginLti /> }/>
					<Route exact path='/logout' element={ <Logout /> }/>
					<Route exact path='/not-allowed' element={ <NotAllowed /> }/>
					<Route exact path='/page-not-found' element={ <NoMatch /> }/>
					<Route
						path="*"
						element={<Navigate to="/page-not-found" replace />}
					/>
				</Routes>
			</Router>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
    	checkLogin: () => {
    		return dispatch(auth.checkLogin());
    	},
	};
};

let RootContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(RootContainerComponent);

const App = () => {
	return (
		<Provider store={store}>
			<RootContainer />
		</Provider>
	);
};

export default App;