import {
    ArcElement,
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {Bar, Pie} from "react-chartjs-2";
import zoomPlugin from 'chartjs-plugin-zoom';
import {Popover, Select} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1,
            }
        },
        x: {
            ticks: {
                stepSize: 1,
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            labels: {
                title: null
            }
        },
    }
};

const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
    }
};

const LadderPosted = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [chartData, setChartData] = useState([])
    const [dashboardType, setDashboardType] = useState(0)
    const [representation, setRepresentation] = useState('bar')
    const [repliedParticipants, setRepliedParticipants] = useState({
            data: [],
            text: ''
        })
    const [unrepliedParticipants, setUnrepliedParticipants] = useState({
            data: [],
            text: ''
        })


    const LegendText =
        <div className="text-sm ml-4 max-w-xs">
        This chart shows you who you have replied to and how often.
        Replying helps to generate discussion as well as to think about another perspective.
        Replying to different participants helps to gather a variety of perspectives and refine your own understanding.
        </div>

    useEffect(() => {
        setDashboardType(props.dashboard ? props.dashboard: 0)
    }, [props.dashboard])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let labels = []
            let myDataValues = []

            let repliedAnnList = []
            props.data.flatComments.forEach((cmt) => {
                const isMine = (cmt.user.uuid === user.uuid)

                if (isMine) repliedAnnList.push(cmt.annotation_id)
            })

            const repliedAnn = props.data.annotations.filter(ann => repliedAnnList.includes(ann.id))
            const unrepliedAnn = props.data.annotations.filter(ann => !repliedAnnList.includes(ann.id))

            let repliedUser = []
            let unrepliedUser = []
            let uniqueRepliedUser = {}
            let uniqueUnrepliedUser = {}

            repliedAnn.forEach(ann => {
                if (!repliedUser.includes(ann.user.uuid)) {
                    repliedUser.push(ann.user.uuid)
                    uniqueRepliedUser[ann.user.uuid] = {
                        name: ann.user.name,
                        count: 1
                    }
                }
                else {
                    uniqueRepliedUser[ann.user.uuid].count += 1
                }
            })

            unrepliedAnn.forEach(ann => {
                if (!unrepliedUser.includes(ann.user.uuid)) {
                    unrepliedUser.push(ann.user.uuid)
                    uniqueUnrepliedUser[ann.user.uuid] = {
                        name: ann.user.name,
                        count: 1
                    }
                }
                else {
                    uniqueUnrepliedUser[ann.user.uuid].count += 1
                }
            })

            let strRepliedUser = []
            let repliedData = []
            let strUnrepliedUser = []
            let unrepliedData = []

            Object.entries(uniqueRepliedUser).map(([_, uData]) => {
                strRepliedUser.push(uData.name + ' (' + uData.count + ' repl' + (uData.count > 1 ? 'ies' : 'y') + ')')
                repliedData.push(uData)
            })

            Object.entries(uniqueUnrepliedUser).map(([_, uData]) => {
                strUnrepliedUser.push(uData.name)
                unrepliedData.push(uData)
            })

            setRepliedParticipants({
                data: repliedData,
                text: strRepliedUser.length > 0 ? strRepliedUser : '-'
            })

            setUnrepliedParticipants({
                data: unrepliedData,
                text: strUnrepliedUser.length > 0 ? strUnrepliedUser : '-'
            })

            Object.entries(uniqueRepliedUser).forEach(([tId, tData]) => {
                labels.push(tData.name)
                myDataValues.push(tData.count)
            })

            Object.entries(uniqueUnrepliedUser).forEach(([tId, tData]) => {
                labels.push(tData.name)
                myDataValues.push(0)
            })
            
            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Posted Annotation/Replies',
                        data: myDataValues,
                        backgroundColor: [
                            'rgba(75, 192, 132, 0.4)',
                            'rgba(53, 162, 235, 0.4)',
                        ]
                    }
                ],
            })

            setLoading(false)
        }
    }, [props.data, user])

    const onDashboardChange = (value) => {
        setRepresentation(value)
    }
    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="mb-2 font-bold">
                {props.title}
                <Popover placement="bottomRight" content={LegendText} className="ml-1">
                    <InfoCircleOutlined className="text-activeBlue" />
                </Popover>
            </h2>
            {
                dashboardType === 2 &&
                <Select
                    defaultValue="bar"
                    size="small"
                    style={{
                        width: 140,
                    }}
                    onChange={onDashboardChange}
                    options={[
                    {
                        value: 'bar',
                        label: 'Bar Chart',
                    },
                    {
                        value: 'pie',
                        label: 'Pie Chart',
                    },
                    ]}
                />
            }
            <div className="w-full px-4 flex-1 mt-1">
            {
            (chartData.labels.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
            <div className="w-full flex flex-col">
                <div className="w-full h-32 flex">
                {
                (dashboardType === 2) && (representation == 'pie') ?
                <>
                    <Pie data={chartData} options={pieOptions} />
                </>
                :
                <Bar
                    data={chartData}
                    options={options}
                />
                }
                </div>
                <div>
                    <div className="flex flex-col mt-4">
                        <div className="flex flex-row font-medium text-xs">
                            <p className="">I have replied to {repliedParticipants.data.length} participant(s)</p>
                            {
                            dashboardType === 1 &&
                            <p className="pl-4 text-primaryBlue">Class average: 2.5</p>
                            }
                        </div>
                        <p className="font-medium text-xs">Here are the {repliedParticipants.data.length} participant(s) I have replied to the most:</p>
                        <p className="text-xs">{repliedParticipants.text}</p>

                        <div className="mt-4 flex flex-row font-medium text-xs">
                            <p className="">I have NOT replied to {unrepliedParticipants.data.length} participant(s) (who have posted).</p>
                            {
                            dashboardType === 1 &&
                            <p className="pl-4 text-primaryBlue">Class average: 1.9</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
            </div>
        </div>
    )
}

export default LadderPosted;