import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReactWordcloud from 'react-wordcloud';
import {Popover, Select, Table} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

const LadderCloud = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [chartData, setChartData] = useState([])
    const [max, setMax] = useState(100);
    const [dashboardType, setDashboardType] = useState(0)
    const [representation, setRepresentation] = useState('cloud')

    const LegendText =
        <div className="text-sm ml-4 max-w-xs">
        This word cloud shows you the concepts and vocabulary that you have used.
        The larger the size of the word, the more often it was used.
        This can help you think about other concepts and vocabulary that you can include in later posts.
        </div>

    useEffect(() => {
        setDashboardType(props.dashboard ? props.dashboard: 0)
    }, [props.dashboard])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let cloudWords =
                props.data.words.mine.map(items => ({ ...items, owner: 'mine'})).concat(
                    props.data.words.others.map(items => ({ ...items, owner: 'others'}))
                )

            const wordCount = cloudWords.map((r) => {
              return r.value;
            });

            setMax(Math.max(...wordCount));
            setChartData(cloudWords)

            setLoading(false)
        }
    }, [props.data])

    const callbacks = {
      getWordColor: word => (
          word.owner === "mine" ? "blue" : word.owner === "others" ? "purple" : "brown"
      ),
      // onWordClick: console.log,
      // onWordMouseOver: console.log,
      // getWordTooltip: word => `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
    }
    const defaultFontSize = 14
    const options = {
        rotations: 0,
        rotationAngles: [-90, 90],
        fontSizes: [defaultFontSize, max*defaultFontSize],
        padding: 1,
        scale: 'linear',
        layout: 'archimedean',
    };
    const size = [640, 400];

    const createList = (wordList) => {
        const sortedData = [...wordList].sort((a, b) => a.value > b.value ? -1 : 1)
        const columns = [
            {
                title: 'Word',
                dataIndex: 'text',
                key: 'text',
            },
            {
                title: 'Number of times used',
                dataIndex: 'value',
                key: 'value',
            },
        ]

        return (
            <div className='flex max-h-60 overflow-y-scroll'>
                <Table dataSource={sortedData} columns={columns} />
            </div>
        )
    }
    const onDashboardChange = (value) => {
        setRepresentation(value)
    }
    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="mb-2 font-bold">
                {props.title}
                <Popover placement="bottomRight" content={LegendText} className="ml-1">
                    <InfoCircleOutlined className="text-activeBlue" />
                </Popover>
            </h2>
            {
                dashboardType === 2 &&
                <Select
                    defaultValue="cloud"
                    style={{
                        width: 140,
                    }}
                    onChange={onDashboardChange}
                    options={[
                    {
                        value: 'cloud',
                        label: 'Word Cloud',
                    },
                    {
                        value: 'list',
                        label: 'List',
                    },
                    ]}
                />
            }
            <div className="w-full px-4 flex-1 overflow-hidden mt-1">
            {
            (chartData.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
                (dashboardType === 2) && (representation == 'list') ?
                <>
                    {createList()}
                </>
                :
                <div style={{height: "100%", width: "100%"}}>
                    <ReactWordcloud
                        callbacks={callbacks}
                        options={options}
                        words={chartData}
                    />
                </div>
            }
            </div>
        </div>
    )
}

export default LadderCloud;