
import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import Sidebar from "../components/Sidebar";

import { BASE_URL } from "../services/server"
import axios from "axios";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

const { Search } = Input;

export default function Notifications() {
    const [page, setPage] = useState(1)
    const [notifications, setNotifications] = useState(null)
    const [notificationLoading, setNotificationLoading] = useState(false)

    const getNotifications = () => {
        setNotificationLoading(true)

        axios.get(BASE_URL + 'user/notifications')
            .then((resp) => {
                setNotifications(resp.data)
            }).finally(() => {
                setNotificationLoading(false)
            })
    }
    useEffect(() => {
        getNotifications()
    }, [])

    return(
        <div className="Video flex flex-row min-h-screen">
            <Sidebar/>
            <div className="w-full bg-primaryBackground flex flex-col p-4">
                <div className="w-full">
                    <div className="w-full flex items-center mb-4">
                        Unread Notifications
                    </div>
                    <div className="flex flex-col mb-8">
                        <div>
                            {
                                notifications &&
                                (notifications.total_items === 0) &&
                                <span className="text-gray-600">
                                    No new notification
                                </span>
                            }
                            {
                            notifications !== null && notifications.items !== undefined && notifications.items !== null &&
                            notifications.items.map((notifItem) => {
                                const userLink = notifItem.action === "shared" ?
                                    "/video/" + notifItem.video.id : "/video/" + notifItem.video.id
                                const userAction = notifItem.action === "shared" ?
                                    ("shared a new video: " + notifItem.video.title) :
                                     notifItem.action === "annotate" ? ("put annotation on " + notifItem.video.title) : notifItem.action
                                return (
                                <Link to={userLink}
                                      key={notifItem.id}
                                      className="flex py-3 px-4 border-b hover:bg-gray-100">
                                    <div className="flex-shrink-0">
                                        <img className="w-11 h-11 rounded-full"
                                             src={
                                                (notifItem.sender.avatar !== null) ? notifItem.avatar : "/img/user.png"
                                             }
                                             alt={notifItem.name} />
                                    </div>
                                    <div className="pl-3 w-full">
                                        <div className="text-gray-500 font-normal text-sm mb-1.5"><span
                                            className="font-semibold text-gray-900">{ notifItem.sender.name }</span> { userAction }
                                        </div>
                                        <div className="text-xs font-medium text-primary-700">
                                            { dayjs(notifItem.created_at_iso8601).fromNow() }
                                        </div>
                                    </div>
                                </Link>
                                )
                            })
                        }
                        </div>
                        <div className="mt-4 flex justify-center">
                            {
                                notifications !== null &&
                                Array(notifications.total_pages).fill(1).map((_, idx) => {
                                    return (
                                        <div key={idx} className="mx-1">
                                            { idx + 1 }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>         
        </div>
    )
} 