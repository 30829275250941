
import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import Sidebar from "../components/Sidebar";

import { BASE_URL } from "../services/server"
import axios from "axios";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

const { Search } = Input;

export default function Help() {
    const [page, setPage] = useState(1)
    const [notifications, setNotifications] = useState(null)
    const [notificationLoading, setNotificationLoading] = useState(false)

    const getNotifications = () => {
        setNotificationLoading(true)

        axios.get(BASE_URL + 'user/notifications')
            .then((resp) => {
                setNotifications(resp.data)
            }).finally(() => {
                setNotificationLoading(false)
            })
    }
    useEffect(() => {
        getNotifications()
    }, [])

    return(
        <div className="Video flex flex-row min-h-screen">
            <Sidebar/>
            <div className="w-full bg-primaryBackground flex flex-col p-4">
                <div className="w-full">
                    <div className="w-full flex items-center mb-4">
                        Help Page
                    </div>
                    <div className="flex flex-col mb-8">
                        <div>

                        </div>
                    </div>
                </div>
            </div>         
        </div>
    )
} 