import axios from "axios";
import React, { useEffect, useState, useRef } from "react"
import {message, Select, Space, Spin} from "antd"
import { WithContext as ReactTags } from 'react-tag-input';

import * as video from "../../store/video";

import "../../assets/styles/tag.css";
import { BASE_URL } from "../../services/server";
import { useDispatch } from "react-redux";
import { useDisableBodyScroll } from "../../hooks/BodyScroll";
import {DeleteOutlined, ReloadOutlined} from "@ant-design/icons";


const SharedDetailsPanel = (props) => {
    useDisableBodyScroll(props.show);

    const prevVideoRef = useRef();
    const [currentCoachId, setCurrentCoachId] = useState([]);
    const [collaborators, setCollaborators] = useState(props.collaborators.map(collab => collab.uuid));

    useEffect(() => {
        if (
            (prevVideoRef.current !== undefined) &&
            (prevVideoRef.current !== null) &&
            (prevVideoRef.current.show !== props.show)
        ) {
            setCollaborators(props.collaborators.map(collab => collab.uuid))
            setCurrentCoachId(props.coachs.map(c => c.uuid))
        }
        prevVideoRef.current = props
    }, [props])


    const resetData = () => {
        setCurrentCoachId([])
    }

    const closeModal = () => {
        resetData()
        props.onClose && props.onClose();
    }

    if (!props.show) return null;


    return (
            <div className="py-16 transition duration-150 ease-in-out z-10 fixed top-0 right-0 bottom-0 left-0" id="modal"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-2xl">
                    <div className="relative py-8 px-2 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
                            Shared with the following users
                        </h1>
                        <div className="flex flex-col h-56 overflow-scroll border text-sm">
                            {
                                props.collaborators.map((collaborator, idx) => (
                                    <div key={idx}
                                         className={"py-1 px-2 flex justify-between hover:bg-gray-200 " + ((idx % 2 === 0) ? "" : "bg-gray-100")}>
                                        <div className="flex flex-col">
                                            <span>{ collaborator.name } ({ collaborator.email })</span>
                                            <span className="ml-4 text-gray-600">{ collaborator.school.name }</span>
                                        </div>
                                        {
                                            collaborators.includes(collaborator.uuid) &&
                                            <div className="flex justify-end gap-2">
                                                {
                                                    currentCoachId.includes(collaborator.uuid) &&
                                                    <span className="font-semibold mr-2">Coach</span>
                                                }
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="mt-8 flex items-center justify-end w-full">
                            <button
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                        <button
                            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                            onClick={closeModal} aria-label="close modal"
                        >
                            <svg  xmlns="http://www.w3.org/2000/svg"  className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default SharedDetailsPanel;