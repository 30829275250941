import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Filler,
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Bar, Radar } from "react-chartjs-2";
import zoomPlugin from 'chartjs-plugin-zoom';
import {Popover, Select} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

ChartJS.register(
    CategoryScale,
    Filler,
    LinearScale,
    BarElement,
    LineElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
        y: {
            min: 0,
            grid: {
                display: false,
            },
            ticks: {
                stepSize: 1,
            }
        },
        x: {
            ticks: {
                stepSize: 1,
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
            }
        }
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
            }
        },
        datalabels: {
            labels: {
                title: null
            }
        },
    }
};


const radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
};

const LadderMyTags = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [chart1Data, setChart1Data] = useState([])
    const [chart2Data, setChart2Data] = useState([])
    const [dashboardType, setDashboardType] = useState(0)
    const [representation, setRepresentation] = useState('bar')
    const [textUnusedTag, setTextUnusedTag] = useState('')
    const [textUsedTag, setTextUsedTag] = useState('')

    const LegendText =
        <div className="text-sm ml-4 max-w-xs">
        This chart shows you the tags you have used in your posts.
        These tags help you structure your thinking and using a variety of tags helps activate different ways of thinking.
        </div>

    useEffect(() => {
        setDashboardType(props.dashboard ? props.dashboard: 0)
    }, [props.dashboard])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let tags = {}
            let tagIds = []
            let defaultLabels = []
            let defaultValues = []
            let defaultClassValues = []
            let otherLabels = []
            let otherValues = []
            let otherClassValues = []

            props.data.annotations.forEach((ann) => {
                const isMine = (ann.user.uuid === user.uuid)

                ann.tags.forEach((tag) => {
                    if (!tagIds.includes(tag.id)) {
                        tagIds.push(tag.id)
                        const searched_tag = props.data.tags.filter((src) => src.tag === tag.tag)

                        tags[tag.id] = {
                            tag: tag.tag,
                            text: tag.text,
                            mine: isMine ? 1 : 0,
                            total: 1,
                            is_default: (searched_tag.length > 0)
                        }
                    }
                    else {
                        tags[tag.id].total++
                        tags[tag.id].mine += (isMine ? 1 : 0)
                    }
                })
            })

            props.data.flatComments.forEach((cmt) => {
                const isMine = (cmt.user.uuid === user.uuid)

                cmt.tags.forEach((tag) => {
                    if (!tagIds.includes(tag.id)) {
                        tagIds.push(tag.id)
                        const searched_tag = props.data.tags.filter((src) => src.tag === tag.tag)

                        tags[tag.id] = {
                            tag: tag.tag,
                            text: tag.text,
                            mine: isMine ? 1 : 0,
                            total: 1,
                            is_default: (searched_tag.length > 0)
                        }
                    }
                    else {
                        tags[tag.id].total++
                        tags[tag.id].mine += (isMine ? 1 : 0)
                    }
                })
            })

            let usedDefaultTags = []

            Object.entries(tags).forEach(([tId, tData]) => {
                if (tData.is_default) {
                    defaultLabels.push(tData.text)
                    defaultValues.push(tData.mine)
                    defaultClassValues.push(tData.total-tData.mine)

                    if (tData.mine > 0) {
                        usedDefaultTags.push({
                            tag: tData.tag,
                            text: tData.text,
                            count: tData.mine
                        })
                    }
                }
                else {
                    otherLabels.push(tData.text)
                    otherValues.push(tData.mine)
                    otherClassValues.push(tData.total-tData.mine)
                }
            })

            let unusedDefaultTags = []
            Object.entries(props.data.tags).forEach(([tId, tData]) => {
                const isUsed = usedDefaultTags.filter(t => (t.tag === tData.tag)).length > 0

                if (!isUsed) unusedDefaultTags.push(tData.text)
            })

            // usedDefaultTagsData.join(', ')
            let strUnusedTag = unusedDefaultTags.join(', ')
            let strUsedTag = []

            usedDefaultTags.forEach((uTag) => {
                strUsedTag.push(uTag.text)
            })
            strUsedTag = strUsedTag.join(', ')

            setTextUsedTag(strUsedTag.length > 0 ? strUsedTag : "-")
            setTextUnusedTag(strUnusedTag.length > 0 ? strUnusedTag : "-")

            let chart1Datasets = [
                {
                    type: 'bar',
                    label: 'My default tags',
                    data: defaultValues,
                    backgroundColor: 'rgba(75, 192, 132, 0.4)',
                },
            ]

            let chart2Datasets = [
                {
                    label: 'My non-default tags',
                    data: otherValues,
                    backgroundColor: 'rgba(53, 162, 235, 0.4)',
                },
            ]

            if (props.dashboard && props.dashboard === 1) {
                chart1Datasets.push(
                    {
                        type: 'line',
                        label: 'Class average',
                        data: defaultClassValues,
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        borderColor: 'rgba(75, 192, 132, 0.4)',
                        borderDash: [3, 3],
                        borderDashOffset: 3,
                    })

                chart2Datasets.push(
                    {
                        type: 'line',
                        label: 'Class average',
                        data: otherClassValues,
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        borderColor: 'rgba(53, 162, 235, 0.8)',
                        borderDash: [3, 3],
                        borderDashOffset: 3,
                    })
            }
            setChart1Data({
                labels: defaultLabels,
                datasets: chart1Datasets,
            })

            setChart2Data({
                labels: otherLabels,
                datasets: chart2Datasets,
            })

            setLoading(false)
        }
    }, [props.data, user])

    const radarData = {
        labels: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5', 'Tag 6'],
        datasets: [
        {
          label: 'Number of tags used',
          data: [2, 9, 3, 5, 2, 3],
          backgroundColor: 'rgba(99, 132, 255, 0.2)',
          borderColor: 'rgba(99, 132, 255, 1)',
          borderWidth: 1,
        },
        ],
    };

    const onDashboardChange = (value) => {
        setRepresentation(value)
    }
    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="mb-2 font-bold">
                {props.title}
                <Popover placement="bottomRight" content={LegendText} className="ml-1">
                    <InfoCircleOutlined className="text-activeBlue" />
                </Popover>
            </h2>
            {
                dashboardType === 2 &&
                <Select
                    defaultValue="bar"
                    size="small"
                    style={{
                        width: 140,
                    }}
                    onChange={onDashboardChange}
                    options={[
                    {
                        value: 'bar',
                        label: 'Bar Chart',
                    },
                    {
                        value: 'radar',
                        label: 'Radar Chart',
                    },
                    ]}
                />
            }
            <div className="w-full px-4 flex-1 mt-2">
            {
            (chart1Data.labels.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
                <div className="w-full flex flex-col">
                    <div className="w-full flex">
                    {
                    (dashboardType === 2) && (representation == 'radar') ?
                    <>
                        <Radar data={radarData} options={radarOptions}/>
                    </>
                    :
                    <>
                    <div className=" w-3/4">
                    <Bar
                        data={chart1Data}
                        options={options}
                    />
                    </div>

                    <div className="flex flex-col w-1/4 pt-8">
                        <Bar
                            data={chart2Data}
                            options={options}
                        />
                    </div>
                    </>
                    }
                    </div>

                    <div className="flex flex-col mt-4">
                        <p className="text-xs">I have used the default tag(s): &nbsp;
                            <span className="font-semibold">{textUsedTag}</span>
                        </p>
                        <p className="text-xs">I have NOT used the default tag(s):&nbsp;
                            <span className="font-semibold">{textUnusedTag}</span>
                        </p>
                    </div>
                </div>

            }
            </div>
        </div>
    )
}

export default LadderMyTags;