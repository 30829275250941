import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Image } from "antd";
import { useNavigate } from "react-router-dom";
import loginPageLogo from '../assets/loginPageLogo.svg'

import { login, checkLogin } from "../store/auth"
import { clearMessage } from "../store/message";

const LoginPanel = () => {
    const [loading, setLoading] = useState(false);

    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    
    const handleLogin = (e) => {
        e.preventDefault();
        
        setLoading(true);

        dispatch(login({ username, password }))
            .unwrap()
            .then((data) => {
                dispatch(checkLogin())
                if (data.user.access_token) {
                    return navigate("/", { state: { notification: true } });
                }
        })
        .catch(() => {
            setLoading(false);
        });
    }


    return(
        <div className="md:w-1/3 bg-white flex-col flex justify-center items-center relative">
            <Image
                    src={loginPageLogo}
                    width={200}
                    preview={false}
                    className="my-20 bottom-10 right-20 xl:absolute xl:my-0"
            />
            <div className="justify-center items-start mx-16">
                <div className="font-bold text-[30px]">Welcome</div>
                <div className="text-xs pt-1">Please login to your account before continue</div>

                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    className="pt-6"
                >
                    <div className="font-bold pb-2">Username</div>
                    <Form.Item
                        name="username"
                        rules={[{ message: 'Please input your username!' }]}
                    >
                        <Input 
                            placeholder="username" className="p-3"
                            value={username}
                            onChange={e => setUsername(e.currentTarget.value)}
                        />
                    </Form.Item>

                    <div className="font-bold pb-2">Password</div>
                    <Form.Item
                        name="password"
                        rules={[{ message: 'Please input your password!' }]}
                    >
                        <Input.Password 
                            placeholder="password" className="p-3"
                            value={password}
                            onChange={e => setPassword(e.currentTarget.value)}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0 }}>
                        <Button 
                            type="primary" htmlType="submit" className="bg-primaryBlue w-full h-12"
                            disabled={((username.length === 0) || (password.length === 0))}
                            onClick={handleLogin}
                        >Login</Button>
                    </Form.Item>
                </Form>
                <div className="text-xs pt-1">
                    If you have logged in from SLS,
                    please close this window and click on the CoVAAPD app library icon again.
                </div>
            </div>
        </div>
    )
}

export default LoginPanel;