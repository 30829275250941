import axios from "axios";
import { useEffect, useState } from "react"
import { WithContext as ReactTags } from 'react-tag-input';

import * as video from "../store/video";

import "../assets/styles/tag.css";
import { Checkbox, message } from 'antd';

import { BASE_URL } from "../services/server";
import { useDispatch } from "react-redux";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const TagSelectionPanel = (props) => {
    const onChange = (e) => {
        if (e.target.checked) {
            props.setSelectedTags && props.setSelectedTags([
                ...props.selectedTags,
                {
                    id: e.target.options.id,
                    text: e.target.options.label
                }
            ])
        }
        else {
            props.setSelectedTags && props.setSelectedTags([
                ...props.selectedTags.filter(tag => tag.id !== e.target.options.id)
            ])
        }
    };

    const onSelect = () => {
        props.onSelect && props.onSelect(props.selectedTags)
    }

    const closeModal = () => {

    }

    return (
        <>  
            <div className="flex flex-col h-56 overflow-scroll">
                {
                    props.videoTags.length > 0 ?
                    props.videoTags.map((dTag, idx) => {
                        const isChecked = props.selectedTags.filter(tag => tag.id === dTag.id).length > 0
                        return (
                            <div key={idx}>
                                <Checkbox
                                    options={{
                                        id: dTag.id,
                                        label: dTag.text
                                    }}
                                    value={props.selectedTags}
                                    onChange={onChange}
                                    checked={isChecked}
                                >
                                    { dTag.text }
                                </Checkbox>
                            </div>
                        )
                    })
                    :
                    <p>Coach has not assigned default tag(s).</p>
                }
            </div>
            <div className="mt-8 flex items-center justify-between w-full">
                <button 
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" 
                    onClick={props.onCancel}
                >
                    Cancel
                </button>
                <button 
                    className="ml-4 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-activeBlue transition duration-150 ease-in-out hover:bg-activeBlue bg-primaryBlue rounded text-white px-8 py-2 text-sm"
                    onClick={onSelect}
                >
                    Next
                </button>
            </div>
        </>
    )
}

const AnnotationInputPanel = (props) => {
    return (
        <>
            <textarea id="annotation" 
                className="p-2 mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-activeBlue font-normal w-full flex items-center pl-3 text-sm border-gray-300 rounded border resize-none" 
                placeholder="Enter your comments"
                rows="5"
                value={props.comment}
                onChange={(e) => props.setComment(e.currentTarget.value)}
            />
            <div className="flex flex-col">
                <label htmlFor="tags" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                    Tags 
                    <span className="ml-1 font-normal text-xs">(press "Enter" or "Return" to add new tag)</span>
                </label>
                <ReactTags
                    id="tags"
                    placeholder='Press "Enter" to add new tag'
                    tags={props.tags}
                    suggestions={props.videoTags}
                    delimiters={delimiters}
                    handleDelete={props.handleTagDelete}
                    handleAddition={props.handleTagAddition}
                    handleDrag={props.handleTagDrag}
                    handleTagClick={props.handleTagClick}
                    inputFieldPosition="top"
                    autocomplete
                />
                {
                props.errors && props.errors.tags &&
                <span className="text-sm text-red-500 ml-2">
                    { props.errors.tags }
                </span>
                }
            </div>
            <div className={"mt-8 flex items-center w-full " + ((props.editMode) ? "justify-end" : "justify-between")}>
                {
                !props.editMode &&
                <button 
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" 
                    onClick={props.onCancel}
                >
                    Back
                </button>

                }
                <button 
                    className="ml-4 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-activeBlue transition duration-150 ease-in-out hover:bg-activeBlue bg-primaryBlue rounded text-white px-8 py-2 text-sm"
                    onClick={props.onSubmit}
                >
                    Submit
                </button>
            </div>
        </>
    )
}

const ModalAnnotation = (props) => {
    const [page, setPage] = useState(0);
    const [comment, setComment] = useState("")
    const [tags, setTags] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedDefaultTags, setSelectedDefaultTags] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        const { annotationData } = props

        if ((annotationData !== undefined) && (annotationData !== null) && (page === 0)) {
            setPage(1)
            setComment(annotationData.description)
            setTags(annotationData.tags.map(annTag => ({
                ...annTag,
                id: annTag.id.toString()
            })))
        }
        // else setPage(0)
    }, [props])

    const handleSelectDefaulTags = (defaultTags) => {
        setTags(defaultTags)
        setPage(1)
    }

    const handleSubmit = () => {
        if (tags.length > 0) {
            setErrorMessage(null)

            if (props.annotationData === null) {
                axios.post(BASE_URL + 'annotation', {
                    "video_id": parseInt(props.videoId),
                    "time": parseInt(Math.round(props.ts)),
                    "description": comment,
                    "privacy": 0,
                    "status": 0,
                    tags
                }).then((res) => {
                    dispatch(video.get(props.videoId))
                    closeModal()
                })
            }
            else {
                axios.put(BASE_URL + 'annotation/' + props.annotationData.id, {
                    "description": comment,
                    "time": props.annotationData.time,
                    "privacy": props.annotationData.privacy,
                    "status": props.annotationData.status,
                    tags
                }).then((res) => {
                    dispatch(video.get(props.videoId))
                    closeModal()
                    message.success('Annotation has been edited.')
                })
                .catch(() => {
                    message.error('Annotation cannot be edited.');
                })
            }
        }
        else {
            setErrorMessage({
                tags: 'Please enter at least 1 tag.'
            })
        }
        
    }

    const handleTagDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
      };
    
    const handleTagAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleTagDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const resetData = () => {
        setComment("")
        setTags([])
        setPage(0)
    }
    
    const handleBackModal = () => {
        setPage(0)
        setErrorMessage(null)
    }

    const closeModal = () => {
        resetData()
        props.onClose && props.onClose();
    }

    if (!props.show) return null;

    return (
            <div className="py-32 transition duration-150 ease-in-out z-10 fixed top-0 right-0 bottom-0 left-0" id="modal"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-xl">
                    <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <div className="w-full flex justify-start text-gray-600 mb-3">
                            <svg fill="none" width="52" height="52" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"/>
                            </svg>
                        </div>
                        <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
                            {page === 0 ? "Select Tag(s) for " : ""} Comments
                        </h1>
                        <div>
                            {
                                page === 0 ?
                                    <TagSelectionPanel 
                                        onSelect={handleSelectDefaulTags}
                                        onCancel={closeModal}
                                        selectedTags={selectedDefaultTags}
                                        setSelectedTags={setSelectedDefaultTags}
                                        videoTags={props.videoTags}
                                    />
                                :
                                    <AnnotationInputPanel 
                                        editMode={props.annotationData !== null}
                                        comment={comment}
                                        tags={tags}
                                        videoTags={props.videoTags}
                                        errors={errorMessage}

                                        setComment={setComment}
                                        setTags={setTags}
                                        
                                        handleTagDelete={handleTagDelete}
                                        handleTagAddition={handleTagAddition}
                                        handleTagDrag={handleTagDrag}
                                        handleTagClick={handleTagClick}

                                        onSubmit={handleSubmit}
                                        onCancel={handleBackModal}
                                    />
                            }
                            <button 
                                className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" 
                                onClick={closeModal} aria-label="close modal"
                            >
                                <svg  xmlns="http://www.w3.org/2000/svg"  className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ModalAnnotation;