import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import axios from "axios";
import {BASE_URL} from "../services/server";

dayjs.extend(relativeTime)

const Topbar = (props) => {
    const [showList, setShowList] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [notificationLoading, setNotificationLoading] = useState(false)
    const wrapperRef = useRef(null);
    const triggerRef = useRef(null);

    const linkUserGuide = "/doc/CoVAAPD-UserGuide.pdf"

    const getNotifications = () => {
        setNotificationLoading(true)

        axios.get(BASE_URL + 'user/notifications')
            .then((resp) => {
                setNotifications(resp.data.items)
            }).finally(() => {
                setNotificationLoading(false)
            })
    }

    useEffect(() => {
        getNotifications()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && triggerRef.current &&
                !(
                    wrapperRef.current.contains(event.target) ||
                    triggerRef.current.contains(event.target)
                )
            ) {
                if (showList) setShowList(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, triggerRef]);

    const toggleNotificationList = () => {
        setShowList(!showList)
    }

    const markRead = (idx) => {
        axios.post(BASE_URL + 'user/notifications/' + idx + '/read')
    }

    return(
        <div
            className="relative w-full bg-white text-right border py-0 px-2 z-10">
            <Link to={linkUserGuide} target="_blank" rel="noopener noreferrer"
                  className="relative inline-flex w-fit mr-6"
            >
                <div className="text-activeBlue flex flex-row justify-center items-center cursor-pointer">
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                            d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                </div>
            </Link>
            <button ref={triggerRef} className="relative inline-flex w-fit mt-4 mr-6" onClick={toggleNotificationList}>
                { notifications.length > 0 &&
                <div
                    className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-blue-700 p-1.5 text-center align-baseline text-xs font-medium leading-none text-white">
                    { notifications.length }
                </div>
                }
                <div className="text-activeBlue flex flex-row justify-center items-center cursor-pointer">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                         aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"/>
                    </svg>
                </div>
            </button>
            <div ref={wrapperRef} className={
                (showList ? "absolute" : "hidden") +
                " right-4 top-10 border overflow-y-auto z-999 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg"
                }
                aria-modal="true">
                <div>
                {
                    (notifications.length === 0) &&
                    <div className="flex py-4 px-10 border-b hover:bg-gray-100">No notification</div>
                }
                {
                    notifications.map((notifItem) => {
                        const userLink = notifItem.action === "shared" ?
                            "/video/" + notifItem.video.id : "/video/" + notifItem.video.id
                        const userAction = notifItem.action === "shared" ?
                            ("shared a new video: " + notifItem.video.title) :
                             notifItem.action === "annotate" ? ("put annotation on " + notifItem.video.title) : notifItem.action
                        return (
                        <Link to={userLink}
                          key={notifItem.id}
                          onClick={() => markRead(notifItem.id)}
                          className="flex py-3 px-4 border-b hover:bg-gray-100">
                            <div className="flex-shrink-0">
                                <img className="w-11 h-11 rounded-full"
                                     src={
                                        (notifItem.sender.avatar !== null) ? notifItem.avatar : "/img/user.png"
                                     }
                                     alt={notifItem.name} />
                            </div>
                            <div className="pl-3 w-full">
                                <div className="text-gray-500 font-normal text-sm mb-1.5"><span
                                    className="font-semibold text-gray-900">{ notifItem.sender.name }</span> { userAction }
                                </div>
                                <div className="text-xs font-medium text-primary-700">
                                    { dayjs(notifItem.created_at_iso8601).fromNow() }
                                </div>
                            </div>
                        </Link>
                        )
                    })
                }
                </div>
                { notifications.length > 0 &&
                <Link to="/notifications"
                      className="block py-1 text-base font-normal text-center text-gray-900 bg-gray-50 hover:bg-gray-100"
                      title="Notifications">
                    <div className="inline-flex items-center text-sm ">
                        <svg aria-hidden="true" className="mr-2 w-3 h-3" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                            <path fillRule="evenodd"
                                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                  clipRule="evenodd"></path>
                        </svg>
                        View all
                    </div>
                </Link>
                }
            </div>
        </div>
    )
}

export default Topbar;
