import axios from "axios";
import { useEffect, useState } from "react"
import { message } from "antd"
import { WithContext as ReactTags } from 'react-tag-input';

import * as video from "../store/video";

import "../assets/styles/tag.css";
import { BASE_URL } from "../services/server";
import { useDispatch } from "react-redux";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = [
    {id: "0", text: "Suggested Tag 1"},
    {id: "1", text: "Suggested Tag 2"},
    {id: "2", text: "Value Tag"},
    {id: "2", text: "Good movement"}
]

const ModalComment = (props) => {
    const [comment, setComment] = useState("")
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const { commentData } = props
        if ((commentData !== undefined) && (commentData !== null)) {
            setComment(commentData.description)
            setTags(commentData.tags.map(cmtTag => ({
                ...cmtTag,
                id: cmtTag.id.toString()
            })))
        }
    }, [props])
    
    const handleSubmit = () => {
        if (props.commentData === null) {
            axios.post(BASE_URL + 'comment', {
                video_id: parseInt(props.videoId),
                annotation_id: parseInt(props.annotationId),
                parent_id: props.commentParent,
                description: comment,
                tags
            }).then((res) => {
                resetData()
                dispatch(video.get(props.videoId))
                props.onClose()
                message.success('Comment has been posted.')
            })
            .catch(() => {
                message.error('Comment cannot be posted.');
            })
        }
        else {
            axios.put(BASE_URL + 'comment/' + props.commentData.id, {
                description: comment,
                annotation_id: props.commentData.annotation_id,
                video_id: props.commentData.video_id,
                tags
            }).then((res) => {
                resetData()
                dispatch(video.get(props.videoId))
                props.onClose()
                message.success('Comment has been edited.')
            })
            .catch(() => {
                message.error('Comment cannot be edited.');
            })
        }
    }

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
      };
    
    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const resetData = () => {
        setComment("")
        setTags([])
    }

    const closeModal = () => {
        resetData()
        props.onClose && props.onClose();
    }

    if (!props.show) return null;

    return (
            <div className="py-32 transition duration-150 ease-in-out z-10 fixed top-0 right-0 bottom-0 left-0" id="modal"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-xl">
                    <div className="relative py-8 px-2 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
                            { props.commentData !== null && "Edit " }Comment
                        </h1>
                        <textarea id="annotation" 
                            className="p-2 mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-activeBlue font-normal w-full flex items-center pl-3 text-sm border-gray-300 rounded border resize-none" 
                            placeholder={"Enter your comment"}
                            rows="5"
                            value={comment}
                            onChange={(e) => setComment(e.currentTarget.value)}
                        />
                        
                        <label htmlFor="tags" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            Tags
                            <span className="ml-1 font-normal text-xs">(press "Enter" or "Return" to add new tag)</span>
                        </label>
                        <ReactTags
                            id="tags"
                            placeholder='Press "Enter" to add new tag'
                            tags={tags}
                            suggestions={suggestions}
                            delimiters={delimiters}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            inputFieldPosition="top"
                            autocomplete
                        />
                        <div className="mt-8 flex items-center justify-between w-full">
                            <button 
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" 
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button 
                                className="ml-4 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-activeBlue transition duration-150 ease-in-out hover:bg-activeBlue bg-primaryBlue rounded text-white px-8 py-2 text-sm"
                                disabled={comment.length===0}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                        <button 
                            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" 
                            onClick={closeModal} aria-label="close modal"
                        >
                            <svg  xmlns="http://www.w3.org/2000/svg"  className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default ModalComment;