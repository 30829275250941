import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Bar } from "react-chartjs-2";
import zoomPlugin from 'chartjs-plugin-zoom';

import {Popover, Select} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

ChartJS.register(
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
        y: {
            display: false,
            beginAtZero: true,
            stacked: true,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            }
        },
        x: {
            display: false,
            beginAtZero: true,
            stacked: true,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
            formatter: (value, context) => {
                let labelText = `${value}/${context.dataset.target}`
                if (value === 0) labelText = '         ' + labelText
                return labelText
            }
        }
    }
};

const TARGET_ANN = 2
const TARGET_REPLY = 2

const LadderReplies = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [textData, setTextData] = useState([0, 0])
    const [chartData1, setChartData1] = useState([])
    const [chartData2, setChartData2] = useState([])
    const [dashboardType, setDashboardType] = useState(0)
    const [representation, setRepresentation] = useState('progress')

    const LegendText =
        <div className="text-sm ml-4 max-w-xs">
        This chart shows whether you have achieved the target number of annotations and replies set by your teacher.
            You are encouraged to meet the target and do even more.
            Posting annotations and replies can help you engage with the content and contribute to the overall discussion.
        </div>


    useEffect(() => {
        setDashboardType(props.dashboard ? props.dashboard: 0)
    }, [props.dashboard])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let bars = {}

            let labels = []
            let myDataValues = []
            let myTargetValues = []

            let dValues = [[], []]
            let tValues = [[], []]

            let dataValues = []
            let annData = {
                mine: 0,
                total: 0,
            }

            let cmtData = {
                mine: 0,
                total: 0,
            }

            props.data.annotations.forEach((ann) => {
                const isMine = (ann.user.uuid === user.uuid)
                annData = {
                    mine: annData.mine + (isMine ? 1 : 0),
                    total: annData.total + 1
                }
            })

            props.data.flatComments.forEach((cmt) => {
                const isMine = (cmt.user.uuid === user.uuid)
                cmtData = {
                    mine: cmtData.mine + (isMine ? 1 : 0),
                    total: cmtData.total + 1
                }
            })

            dValues[0] = [annData.mine]
            dValues[1] = [cmtData.mine]
            setTextData([annData.mine, cmtData.mine])

            tValues[0] = [annData.mine > TARGET_ANN ? 0 : TARGET_ANN - annData.mine]
            tValues[1] = [cmtData.mine > TARGET_REPLY ? 0 : TARGET_REPLY - cmtData.mine]

            // dummy
            let tags = [
                {text: 'Data'},
                {text: 'Total'},
            ]

            Object.entries(tags).forEach(([tId, tData]) => {
                labels.push(tData.text)
            })

            setChartData1({
                labels,
                datasets: [
                    {
                        label: 'Annotations',
                        data: dValues[0],
                        target: TARGET_ANN,
                        datalabels: {
                            color: 'black'
                        },
                        backgroundColor: [
                            tValues[0] > 0 ?
                                'rgba(255, 26, 104, 0.4)':
                                'rgba(75, 192, 132, 0.4)'
                        ],
                        borderColor: [
                            tValues[0] > 0 ?
                                'rgba(255, 26, 104, 0.4)':
                                'rgba(75, 192, 132, 0.4)'
                        ],
                        borderWidth: 1,
                        borderSkipped: false,
                        barPercentage: 0.6,
                        categoryPercentage: 0.8,
                    },
                    {
                        label: 'Replies',
                        data: tValues[0],
                        target: TARGET_ANN,
                        datalabels: {
                            labels: {
                                title: null
                            }
                        },
                        backgroundColor: [
                            'rgba(30, 30, 30, 0.2)',
                            'rgba(30, 30, 30, 0.2)'
                        ],
                        borderColor: [
                            'rgba(30, 30, 30, 0.2)',
                            'rgba(30, 30, 30, 0.2)'
                        ],
                        borderWidth: 1,
                        borderSkipped: false,
                        barPercentage: 0.6,
                        categoryPercentage: 0.8,
                    }
                ],
            })


            setChartData2({
                labels,
                datasets: [
                    {
                        label: 'Comments',
                        data: dValues[1],
                        target: TARGET_REPLY,
                        backgroundColor: [
                            tValues[1] > 0 ?
                                'rgba(255, 26, 104, 0.4)':
                                'rgba(75, 192, 132, 0.4)'
                        ],
                        borderColor: [
                            tValues[1] > 0 ?
                                'rgba(255, 26, 104, 0.4)':
                                'rgba(75, 192, 132, 0.4)'
                        ],
                        borderWidth: 1,
                        borderSkipped: false,
                        barPercentage: 0.6,
                        categoryPercentage: 0.8,
                    },
                    {
                        label: 'Replies',
                        data: tValues[1],
                        target: TARGET_REPLY,
                        datalabels: {
                            labels: {
                                title: null
                            }
                        },
                        backgroundColor: [
                            'rgba(30, 30, 30, 0.2)',
                            'rgba(30, 30, 30, 0.2)'
                        ],
                        borderColor: [
                            'rgba(30, 30, 30, 0.2)',
                            'rgba(30, 30, 30, 0.2)'
                        ],
                        borderWidth: 1,
                        borderSkipped: false,
                        barPercentage: 0.6,
                        categoryPercentage: 0.8,
                    }
                ],
            })


            setLoading(false)
        }
    }, [props.data, user])

    const onDashboardChange = (value) => {
        setRepresentation(value)
    }

    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="mb-2 font-bold">
                {props.title}
                <Popover placement="bottomRight" content={LegendText} className="ml-1">
                    <InfoCircleOutlined className="text-activeBlue" />
                </Popover>
            </h2>
            {
                dashboardType === 2 &&
                <Select
                    defaultValue="progress"
                    size="small"
                    style={{
                        width: 140,
                    }}
                    onChange={onDashboardChange}
                    options={[
                    {
                        value: 'progress',
                        label: 'Progress Chart',
                    },
                    {
                        value: 'text',
                        label: 'Text',
                    },
                    ]}
                />
            }
            <div className="w-full px-4 flex-1">
            {
            (chartData1.labels.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
            <div className="w-full flex flex-col">
                <div className="w-full flex">
                {
                    (dashboardType === 2) && (representation == 'text') ?
                    <div className="flex flex-col w-full mt-1 justify-center items-center">
                        <div className="flex flex-col bg-gray-200 w-1/2 p-1">
                        <div className="flex w-full p-1">
                            <div className="w-full flex flex-col bg-white items-center">
                                <span className="font-medium">{textData[0]} / {TARGET_ANN}</span>
                                <span>Annotations / Target</span>
                            </div>
                        </div>
                        <div className="flex w-full p-1">
                            <div className="w-full flex flex-col bg-white items-center">
                                <span className="font-medium">{textData[1]} / {TARGET_REPLY}</span>
                                <span>Replies / Target</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    :
                    <>
                    <div className={"flex flex-col " + (dashboardType === 1 ? "w-full md:w-2/3": "w-full")}>
                        <div className="relative h-20 mb-2">
                        <Bar
                            data={chartData1}
                            options={options}
                        />
                        <span className="w-full text-xs absolute bottom-6 text-center">Annotations / Target</span>
                        </div>
                        <div className="relative h-20">
                        <Bar
                            data={chartData2}
                            options={options}
                        />
                        <span className="w-full text-xs absolute bottom-6 text-center">Replies / Target</span>
                        </div>
                    </div>
                    {dashboardType === 1 &&
                    <div className="flex-1 p-4 flex flex-col h-100">
                        <div className="h-full">
                        <p className="items-start">Class average: 2.4</p>
                        </div>
                        <div className="">
                            <p className="items-end self-end">Class average: 1.2</p>
                        </div>

                    </div>
                    }
                    </>
                }
                </div>
                <div className="flex flex-col mt-4">
                    <p className="font-medium text-xs">
                        I have posted {textData[0]} of {TARGET_ANN} annotations
                        ({textData[0] < {TARGET_ANN} ? 'target not met' : 'target met'}) and&nbsp;
                        {textData[1]} of {TARGET_REPLY} replies
                        ({textData[1] < {TARGET_REPLY} ? 'target not met' : 'target met'}).
                    </p>
                </div>
            </div>
            }
            </div>
        </div>
    )
}

export default LadderReplies;