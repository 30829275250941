import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validateEmail } from "../helpers/validator";

import * as auth from "../store/auth";

export default function LoginLti(){
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        const ltiToken = searchParams.get("token")

        dispatch(auth.getUser(ltiToken)).then(res => {
            try {
                const { user } = res.payload

                if (validateEmail(user.email)) {
                    return navigate('/video', { state: { notification: true } })
                }
                else return navigate('/setup')
            }
            catch (err) {
                return navigate('/')
            }
        })
    }, [])

    return(
        <div className="w-full flex-row flex min-h-screen">
            <p className="w-full text-center mt-10">Logging in...</p>
        </div>
    )
} 