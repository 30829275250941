import axios from "axios";
import React, { useEffect, useState, useRef } from "react"
import {message, Select, Space, Spin} from "antd"
import { WithContext as ReactTags } from 'react-tag-input';

import * as video from "../../store/video";

import "../../assets/styles/tag.css";
import { BASE_URL } from "../../services/server";
import { useDispatch } from "react-redux";
import { useDisableBodyScroll } from "../../hooks/BodyScroll";
import {DeleteOutlined, ReloadOutlined} from "@ant-design/icons";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = [
    {id: "0", text: "Suggested Tag 1"},
    {id: "1", text: "Suggested Tag 2"},
    {id: "2", text: "Value Tag"},
    {id: "2", text: "Good movement"}
]

const SharePanel = (props) => {
    useDisableBodyScroll(props.show);

    const prevVideoRef = useRef();
    const [userLoading, setUserLoading] = useState(false)
    const [optUsers, setOptUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [newCollaborators, setNewCollaborators] = useState([]);
    const [coachIds, setCoachIds] = useState([]);
    const [currentCoachId, setCurrentCoachId] = useState([]);
    const [collaborators, setCollaborators] = useState(props.collaborators.map(collab => collab.uuid));

    useEffect(() => {
        getUsers()
    }, [])

    useEffect(() => {
        setCollaborators(props.collaborators.map(collab => collab.uuid))
    }, [props.collaborators])

    useEffect(() => {
        if (
            (prevVideoRef.current !== undefined) &&
            (prevVideoRef.current !== null) &&
            (prevVideoRef.current.show !== props.show)
        ) {
            const existingCoach = props.coachs.map(c => c.uuid)
            setCurrentCoachId(existingCoach)
            setCoachIds(existingCoach)
        }

        prevVideoRef.current = props
    }, [props])

    const getUsers = () => {
        setUserLoading(true)
        axios.get(
            BASE_URL + `user?page=1&per_page=5000`,
        )
            .then((resp) => {
                let userOpt = resp.data.items.map((usr, idx) => (
                    {
                        value: usr.uuid,
                        uuid: usr.uuid,
                        name: usr.name,
                        label: usr.name + " " +
                            usr.email +
                            (((usr.school !== null) && (usr.school.name !== null)) ? " " + usr.school.name : ""),
                        school: usr.school,
                        email: usr.email,
                    }
                ))

                setOptUsers(userOpt)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setUserLoading(false)
            })
    }

    const handleSubmit = () => {
        let newCollab = newCollaborators.map((collab) => collab.value)
        newCollab = newCollab.filter((collab) => !collaborators.includes(collab))

        axios.put(
            BASE_URL + "video/" + props.videoId + "/collaborators",
            {
                collaborator: [
                    ...collaborators,
                    ...newCollab,
                ],
                shared_user: [
                    ...coachIds,
                ]
            }
        )
        .then((resp) => {
            props.refreshData && props.refreshData()
            closeModal()
            message.success('List of users has been updated.')
        })
        .catch((err) => {
            message.error('List of users could not be updated.')
        })
    }

    const resetData = () => {
        setNewCollaborators([])
        setCurrentCoachId([])
        setCoachIds([])
    }

    const closeModal = () => {
        resetData()
        props.onClose && props.onClose();
    }

    if (!props.show) return null;

    const onSearchUser = (value) => {
        // console.log('search: ', value)
    }
    const onSelectUser = (value) => {
        let selUsers = optUsers.filter(usr => usr.value === value[0])
        if (selUsers.length > 0) {
            let newCollabs = newCollaborators.filter(collab => collab.value === selUsers[0].value)
            if ((newCollabs.length === 0) && !collaborators.includes(selUsers[0].value)) {
                newCollabs = [...newCollaborators, selUsers[0]]
                newCollabs.sort((a, b) => (a.label < b.label ? -1 : 1))

                setNewCollaborators(newCollabs)
            }
        }
    }

    const handleDeleteNewCollab = (collabId) => {
        setNewCollaborators(
            newCollaborators.filter(collab => collab.value !== collabId)
        )
    }
    const deleteCollab = (collabId) => {
        setCollaborators(collaborators.filter(collab => collab !== collabId))
        setCoachIds(coachIds.filter(cId => cId !== collabId))
    }

    const undoDeleteCollab = (collabId) => {
        setCollaborators([...collaborators, collabId])
        if (currentCoachId.includes(collabId)) setNewCoach(collabId)
    }

    const setNewCoach = (collabId) => {
        if (!coachIds.includes(collabId)) {
            setCoachIds([
                ...coachIds,
                collabId
            ])
        }
    }

    const removeCoach = (e) => {
        const targetId = e.currentTarget.dataset.id
        setCoachIds(coachIds.filter(cId => cId !== targetId))
    }

    const selectOptionRender = (props) => {
        const { data } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Space className="flex flex-col items-start">
                <div>{data.name} (<span>{data.email}</span>)</div>
                {
                data.school &&
                <div className="ml-4 -mt-2 text-gray-600">{data.school.name}</div>
                }
            </Space>
        );
    }

    return (
            <div className="py-16 transition duration-150 ease-in-out z-10 fixed top-0 right-0 bottom-0 left-0" id="modal"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-2xl">
                    <div className="relative py-8 px-2 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
                            Share with...
                        </h1>
                        <div className="flex gap-2">
                            {/*
                            <Select
                                showSearch
                                mode="multiple"
                                className="w-full mb-2 flex-1"
                                placeholder="Select a user"
                                optionFilterProp="children"
                                value={selectedUsers}
                                onChange={onSelectUser}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={optUsers}
                                optionRender={selectOptionRender}
                            />
                            */}

                            <Select
                                showSearch
                                autoClearSearchValue={false}
                                mode="multiple"
                                className="w-full mb-2 flex-1"
                                placeholder="Select a user"
                                optionFilterProp="children"
                                value={selectedUsers}
                                onChange={onSelectUser}
                                onSearch={onSearchUser}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                            {
                                optUsers.map((opt, idx) => {
                                    const isSelected = (newCollaborators.filter(collab => collab.value === opt.uuid).length > 0)

                                    return (
                                    <Select.Option
                                        key={idx} value={opt.uuid} label={opt.label}
                                        className={isSelected ? "bg-blue-100 hover:bg-blue-100" : ""}
                                    >
                                        <Space className="flex flex-col items-start">
                                            <div>{opt.name} (<span>{opt.email}</span>)</div>
                                            {
                                            opt.school &&
                                            <div className="ml-4 -mt-2 text-gray-600">{opt.school.name}</div>
                                            }
                                        </Space>
                                    </Select.Option>
                                )})
                            }
                            </Select>

                            {
                                userLoading ?
                                    <div className="flex items-center justify-center">
                                        <Spin />
                                    </div>
                                    :
                                    <button className="flex border border-blue-400 rounded-md h-8 w-8 items-center justify-center"
                                    onClick={() => getUsers()}
                                    >
                                        <ReloadOutlined/>
                                    </button>
                            }

                        </div>

                        <label htmlFor="tags" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            To be added ({ newCollaborators.length })
                        </label>
                        <div className="mb-4 flex flex-col h-24 overflow-scroll border text-sm">
                            {
                                newCollaborators.map((collaborator, idx) => (
                                    <div key={idx}
                                         className={"py-1 px-2 flex justify-between hover:bg-gray-200 " + ((idx % 2 === 0) ? "" : "bg-gray-100")}>
                                        <div className="flex flex-col">
                                            <span>{ collaborator.name } ({ collaborator.email })</span>
                                            {
                                            collaborator.school &&
                                            <span className="ml-4 text-gray-600">{ collaborator.school.name }</span>
                                            }
                                        </div>

                                        <div className="flex justify-end gap-2">
                                            {
                                                (coachIds.includes(collaborator.value)) ?
                                                <span className="flex flex-col text-right">
                                                    <span className="text-red-600">To be set as coach</span>
                                                    <span
                                                        className="hover:underline cursor-pointer text-xs"
                                                        data-id={collaborator.value}
                                                        onClick={removeCoach}
                                                    >Cancel</span>
                                                </span>
                                                :
                                                <span className="cursor-pointer text-xs hover:underline"
                                                     onClick={() => {setNewCoach(collaborator.value)}}
                                                >Set as Coach</span>
                                            }

                                            <span className="flex items-center cursor-pointer"
                                                 onClick={() => handleDeleteNewCollab(collaborator.value)}
                                            ><DeleteOutlined /></span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <label htmlFor="tags" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            Share with { props.collaborators.length } users
                        </label>
                        <div className="flex flex-col h-56 overflow-scroll border text-sm">
                            {
                                props.collaborators.map((collaborator, idx) => (
                                    <div key={idx}
                                         className={"py-1 px-2 flex justify-between hover:bg-gray-200 " + ((idx % 2 === 0) ? "" : "bg-gray-100")}>
                                        <div className="flex flex-col">
                                            <span>{ collaborator.name } ({ collaborator.email })</span>
                                            <span className="ml-4 text-gray-600">{ collaborator.school.name }</span>
                                        </div>
                                        {
                                            collaborators.includes(collaborator.uuid) ?
                                                <div className="flex justify-end gap-2">
                                                    {
                                                        currentCoachId.includes(collaborator.uuid) ?
                                                        <span className="flex flex-col item-end text-right text-xs">
                                                            <span className="font-semibold">Coach</span>
                                                            {
                                                            (coachIds.includes(collaborator.uuid)) ?
                                                            <span
                                                                className="hover:underline cursor-pointer text-gray-600"
                                                                data-id={collaborator.uuid}
                                                                onClick={removeCoach}
                                                            >Remove from coach</span>
                                                            :
                                                            <span className="flex flex-col">
                                                                <span className="text-red-600">To be removed from coach</span>
                                                                <span
                                                                    className="hover:underline cursor-pointer"
                                                                    onClick={() => { setNewCoach(collaborator.uuid) }}
                                                                >Cancel</span>
                                                            </span>
                                                            }
                                                        </span>
                                                        :
                                                        (
                                                        (coachIds.includes(collaborator.uuid)) ?
                                                        <span className="flex flex-col text-right">
                                                            <span className="text-red-600">To be set as coach</span>
                                                            <span
                                                                className="hover:underline cursor-pointer text-xs"
                                                                data-id={collaborator.uuid}
                                                                onClick={removeCoach}
                                                            >Cancel</span>
                                                        </span>
                                                        :
                                                        <span className="cursor-pointer text-xs hover:underline"
                                                            onClick={() => {setNewCoach(collaborator.uuid)}}
                                                        >Set as Coach</span>
                                                        )
                                                    }
                                                    <span className="flex items-center cursor-pointer"
                                                          onClick={() => deleteCollab(collaborator.uuid)}
                                                    >
                                                        <DeleteOutlined />
                                                    </span>
                                                </div>
                                            :
                                                <span className="flex flex-col text-right">
                                                    <span className="text-red-600">Remove access</span>
                                                    <span
                                                        className="hover:underline cursor-pointer text-xs"
                                                        onClick={() => { undoDeleteCollab(collaborator.uuid) }}
                                                    >Cancel</span>
                                                </span>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="mt-8 flex items-center justify-between w-full">
                            <button
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="ml-4 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-activeBlue transition duration-150 ease-in-out hover:bg-activeBlue bg-primaryBlue rounded text-white px-8 py-2 text-sm"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                        <button
                            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                            onClick={closeModal} aria-label="close modal"
                        >
                            <svg  xmlns="http://www.w3.org/2000/svg"  className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default SharePanel;