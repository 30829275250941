import { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {Collapse, Select, Button, message, Tooltip} from "antd"
import Icon, { LeftOutlined } from "@ant-design/icons"
import ReactPlayer from 'react-player'
import Input from "antd/es/input/Input";

import CommentPanel from "../components/CommentPanel"
import PostCard from "../components/PostCard"
import VideoTimeline from "../components/VideoTimeline"
import axios from "axios"

import { BASE_URL } from "../services/server"
import * as video from "../store/video"
import { trimString } from "../helpers/string";

const AnalyticsSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6526 15.5162C11.6266 15.5162 11.598 15.5162 11.572 15.5136C11.2158 15.4824 10.9116 15.2432 10.792 14.9052L8.97463 9.6558L8.18163 11.3276C8.03083 11.6448 7.71104 11.8476 7.36003 11.8476H4.72104C4.21924 11.8476 3.81104 11.4394 3.81104 10.9376C3.81104 10.4358 4.21924 10.0276 4.72104 10.0276H6.78544L8.28823 6.866C8.44684 6.5332 8.78743 6.3304 9.15923 6.3486C9.52843 6.3694 9.84823 6.6112 9.96783 6.9596L11.8502 12.3988L12.9266 10.4852C13.0878 10.1992 13.392 10.0224 13.7196 10.0224H16.421C16.9228 10.0224 17.331 10.4306 17.331 10.9324C17.331 11.4342 16.9228 11.8424 16.421 11.8424H14.25L12.443 15.0534C12.2818 15.342 11.9776 15.5188 11.65 15.5188L11.6526 15.5162Z" fill="#989895"/>
        <path d="M17.8276 20.9528H3.78761C2.13921 20.9528 0.797607 19.6112 0.797607 17.9628V3.9228C0.797607 2.2744 2.13921 0.9328 3.78761 0.9328H17.8276C19.476 0.9328 20.8176 2.2744 20.8176 3.9228V17.9628C20.8176 19.6112 19.476 20.9528 17.8276 20.9528ZM3.78761 2.7528C3.14281 2.7528 2.61761 3.278 2.61761 3.9228V17.9628C2.61761 18.6076 3.14281 19.1328 3.78761 19.1328H17.8276C18.4724 19.1328 18.9976 18.6076 18.9976 17.9628V3.9228C18.9976 3.278 18.4724 2.7528 17.8276 2.7528H3.78761Z" fill="#989895"/>
    </svg>
);
const AnalyticsIcon = (props) => <Icon component={AnalyticsSvg} {...props} />;

const DEFAULT_SORT_OPT = 'latest'


const VideoAnnotationPanel = (props) => {
    const { videoUrl, data } = props

    const [annotations, setAnnotations] = useState([])

    const [activeTimeline, setActiveTimeline] = useState({})
    const [playing, setPlaying] = useState(true)
    const [active, setActive] = useState(false)
    const [secondsElapsed, setSecondsElapsed] = useState(0);
    const [targetTime, setTargetTime] = useState(0);
    const [commentShown, setCommentShown] = useState(null);
    const [duration, setDuration] = useState(null);
    const [keywords, setKeywords] = useState("")
    const [sortOpt, setSortOpt] = useState(DEFAULT_SORT_OPT);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const annotationRef = useRef(null)
    const playerRef = useRef(null)
    const prevVideoId = useRef(null)

    useEffect(() => {
        if (prevVideoId.current !== data.id) {
            if ((data.timeline) && (data.timeline.activity)) {
                let timelineState = {}
                Object.entries(data.timeline.activity).forEach(([_, state]) => {
                    timelineState[state.ts] = state.value
                })

                if (
                    (Object.keys(activeTimeline).length === 0) &&
                    (Object.keys(timelineState).length > 0)
                ) {
                    setActiveTimeline(timelineState)
                }
            }
            else setActiveTimeline({})
            prevVideoId.current = data.id
        }
    }, [props])


    const handleAnnotate = () => {
        setPlaying(false)
        props.handleAnnotate && props.handleAnnotate(secondsElapsed);
    }

    const handleAnnotationClick = (e, annData) => {
        playerRef.current.seekTo(annData.time)
    }

    const handleFlag =  (annotationId, flagEnabled) => {
        // props.handleFlag && props.handleFlag(annotationId)

        axios.patch(BASE_URL + 'annotation/' + annotationId, {
            "status": flagEnabled ? 1 : 0
        }).then((res) => {
            dispatch(video.get(data.id))
        })
    }

    const handleReply = (annotationId) => {
        handleReplyComment(annotationId, 0)
    }

    const handleReplyComment = (annotationId, parentComment) => {
        props.handleReply && props.handleReply(annotationId, parentComment)
    }

    const handleDelete = (annotationId) => {
        axios.delete(
            BASE_URL + 'annotation/' + annotationId
        ).then((res) => {
            dispatch(video.get(data.id))
            hideComments()
            message.success('Annotation has been deleted')
        })
        .catch((err) => {
            message.error('Annotation cannot be deleted')
        })
    }
    
    const handleDeleteComment = (commentId) => {
        axios.delete(
            BASE_URL + 'comment/' + commentId
        ).then((res) => {
            dispatch(video.get(data.id))
            message.success('Comment has been deleted')
        })
        .catch((err) => {
            message.error('Comment cannot be deleted')
        })
    }

    const onSearchChange = (e) => {
        setKeywords(e.currentTarget.value.toLowerCase())
    }
    const onSearch = (value) => {
        // dispatch(video.list({keywords: value}))
    }
    
    const onSortChange = (value) => {
        setSortOpt(value)
    }

    const handleTimelineMouseEvents = (e) => {

    }
    
    const handleTimestampFocus = (e) => {
        setPlaying(false)
    }

    const goToVideoTime = () => {
        playerRef.current.seekTo(targetTime)
        setPlaying(true)
    }

    const onTimelineClick = (ts) => {
        playerRef.current.seekTo(ts)

        const selectedAnn = data.annotations.filter(ann => { return ann.time === ts})
        if (selectedAnn.length > 0) {
            showComments(selectedAnn[0].id)
        }
        else {
            hideComments()
        }
    }

    const showComments = (annId) => {
        setCommentShown(annId)
    }

    const hideComments = () => {
        setCommentShown(null)
    }

    const onVideoProgress = (progress) => {
        setSecondsElapsed(progress.playedSeconds);
        const currentSec = progress.playedSeconds.toFixed()

        if ((activeTimeline[currentSec] === undefined) || (activeTimeline[currentSec] === null)) {
            let lastActive = -1
            for (let i=currentSec-1; i >= 0; i--) {
                if ((activeTimeline[i] === 0) || (activeTimeline[i] === 1)) {
                    setActive(activeTimeline[i] === 1)
                    lastActive = i
                    break;
                }
            }

            if (lastActive < 0) setActive(false)
        }
    }

    useEffect(() => {
        setTargetTime(secondsElapsed.toFixed(0))
    }, [secondsElapsed])

    useEffect(() => {
        let newAnnotationList = data.annotations.slice(0)
        
        if (keywords.length > 0) {

            newAnnotationList = data.annotations.filter(ann => {
                if (ann.description && ann.description.toLowerCase().includes(keywords)) return true
                if (ann.user.name && ann.user.name.toLowerCase().includes(keywords)) return true

                const allTags = Object.entries(ann.tags).map(([key, value]) => value.tag).join(' ')
                if (allTags.includes(keywords)) return true

                return false
            })
        }
        
        let annList = []
        for (const [idx, ann] of Object.entries(newAnnotationList)) {   
            annList.push({
                ...ann,
                comments: data.comments.filter(cmt => { return cmt.annotation_id === ann.id}),
                totalComments: data.flatComments.filter(cmt => { return cmt.annotation_id === ann.id}).length
            })
        }
        
        switch (sortOpt) {
            case "oldest":
                annList.sort((a, b) => a.created_at_iso8601 < b.created_at_iso8601 ? -1 : 1);
                break;
            case "popular":
                annList.sort((a, b) => a.comments.length > b.comments.length ? -1 : 1);
                break;
            default:
                annList.sort((a, b) => a.created_at_iso8601 < b.created_at_iso8601 ? 1 : -1);
                break;
        }

        setAnnotations(annList)
    }, [data, keywords, sortOpt])

    const updateActivityTimeline = (ts, state) => {
        setPlaying(true)
        setActive(state === 1 ? true : false)

        const latestTimeline = {
            ...activeTimeline,
            [ts]: state
        }
        const activityData = Object.keys(latestTimeline).map(k => ({ ts: parseInt(k), value: latestTimeline[k] }))
        setActiveTimeline(latestTimeline)

        // console.log(activityData)
        axios.post(BASE_URL + 'video/' + data.id.toString() + '/timeline', {
            "activity": activityData
        }).then((res) => {

        })
    }

    const startTimer = () => {
        setActive(true)
        updateActivityTimeline(secondsElapsed.toFixed(), 1)
    }

    const stopTimer = () => {
        updateActivityTimeline(secondsElapsed.toFixed(), 0)
    }

    const downloadData = () => {
        axios({
            url: BASE_URL + 'video/' + data.id.toString() + '/data',
            method: 'GET',
            responseType: 'blob',
        }).then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            const filename = resp.headers["content-disposition"].split('filename="')[1].split('";')[0]

            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <div>
            <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-2/3">
                    <div className="w-full flex flex-col md:flex-row items-center mt-8 mb-2">
                        <Tooltip title={data.title} className="flex-1">
                            <span className="font-bold text-myVideosLibrary">
                                { trimString(data.title, 120) }
                            </span>
                        </Tooltip>
                        {
                            (props.hideShareButton !== true) &&
                            (
                            (data !== null) && (data.user.uuid === user.uuid) ?
                            <div>
                                <Button onClick={props.openShare}>Share</Button>
                            </div>
                                :
                            <div>
                                <Button onClick={props.openSharedDetails}>Shared With</Button>
                            </div>
                            )
                        }
                    </div>
                    <ReactPlayer
                        ref={playerRef}
                        url={videoUrl}
                        config={{ file: { 
                            attributes: { 
                                controlsList: 'nodownload',
                                onContextMenu: e => e.preventDefault() 
                            }
                        }}}

                        playing={playing}
                        onPlay={() => setPlaying(true)}
                        onPause={() => setPlaying(false)}
                        onDuration={(duration) => {
                            setDuration(duration)
                        }}
                        onProgress={onVideoProgress}

                        width="100%" 
                        height="480px" 
                        controls
                    />
                    <div className="w-full flex flex-col text-sm">
                        {
                            data && data.type === 'youtube' &&
                            <span className="w-full bg-black text-white p-2">
                                Content in the frame is from an external source.
                            </span>
                        }
                        <Collapse
                            defaultActiveKey={['1']}
                            bordered={false}
                            className="w-full"
                            items={[
                            {
                                key: '1',
                                label: 'Description',
                                children: <p className="font-normal text-gray-600">{data.description}</p>,
                            }]}
                        />
                    </div>

                    <div className="mt-4">
                        <div className="flex w-full flex-row align-center">
                            <div className="w-1/2 flex">
                                <span className="flex mr-2 items-center">Video time</span>
                                <input 
                                    className="w-16 px-2 py-1 rounded-md text-right h-8"
                                    readOnly={playing ? true : false}
                                    value={playing ? secondsElapsed.toFixed(0) : targetTime}
                                    onFocus={handleTimestampFocus}
                                    onChange={(e) => {if (!playing) setSecondsElapsed(parseInt(e.currentTarget.value))}}
                                />
                                <span className="flex ml-1 items-center">s</span>
                                {
                                !playing &&

                                <Button 
                                    type="primary" htmlType="submit" className="bg-gray-500 h-8 ml-4"
                                    onClick={goToVideoTime}
                                > 
                                    <div className="text-uploadVideoButton">Go</div>
                                </Button>
                                }
                            </div>
                            <div className="flex-1 flex pr-12">
                                <Button
                                    type="primary" htmlType="submit" className="bg-primaryBlue h-8"
                                    onClick={handleAnnotate}
                                >
                                    <div className="text-uploadVideoButton">Add Annotation</div>
                                </Button>
                                <Button onClick={downloadData} className="ml-2 h-8">Download Data</Button>
                            </div>

                            <div className="relative">
                                <Link
                                    to={props.isExperiment ? `/ladder/dashboard/${props.dashboardId}` : `/analytics/${data.id}`}
                                    state={{
                                        src: props.isExperiment ? `/ladder/videos/${props.dashboardId}` : `/videos/${data.id}`
                                    }}
                                    className="absolute right-0 h-12"
                                >
                                <Button
                                    type="primary" htmlType="submit"
                                    className="bg-white border border-gray-200 shadow-none h-8 text-gray-800 hover:text-primaryBlue">
                                    <div className="flex justify-center items-center">
                                        <AnalyticsIcon/>
                                    </div>
                                </Button>
                            </Link>
                            </div>
                        </div>
                    </div>
                    {
                        data.timer === true &&
                        <div className="mt-2">
                        {
                            active ?
                            <>
                            <button className="ml-2 text-white rounded-md px-2 border-0 h-8 bg-red-600 hover:bg-red-700">
                                <div className="text-white text-xs" onClick={stopTimer}>Stop Timer</div>
                            </button>
                            <span className="ml-2 text-xs">Timer running, click to stop</span>
                            </>
                            :
                            <>
                            <button
                                className="ml-2 text-white rounded-md px-2 border-0 h-8 bg-green-600 hover:bg-green-700"
                            >
                                <div className="text-white text-xs" onClick={startTimer}>Start Timer</div>
                            </button>
                            <span className="ml-2 text-xs">Timer stopped, click to start</span>
                            </>
                        }
                        </div>
                    }
                    {
                    data.timer === true &&
                    Object.keys(activeTimeline).length > 0 &&
                    <div className="mt-2 ml-2 text-xs">
                        Timer has started, please only click when you are the designated timekeeper.
                    </div>
                    }
                    <div className="mt-4">
                        <VideoTimeline
                            ref={annotationRef}
                            ts={Math.floor(secondsElapsed)}
                            duration={duration}
                            user={user}
                            annotations={data.annotations}
                            onMouseEvent={handleTimelineMouseEvents}
                            onClick={onTimelineClick}
                        />
                    </div>
                    <div className="flex flex-col mt-4 text-sm">
                        <span className="font-medium">Legend</span>
                        <div><span className="inline-block mr-2 w-2 h-2 bg-[#FF8000]"></span><span>My annotation</span></div>
                        <div><span className="inline-block mr-2 w-2 h-2 bg-[#1177d1]"></span><span>Others' annotation</span></div>
                        <div><span className="inline-block mr-2 w-2 h-2 bg-red-500"></span><span>Flagged annotation</span></div>
                    </div>
                </div>
                <div className="flex-1 background-white min-h-screen lg:pl-8">
                    <div className="flex flex-col w-full h-full shadow-none lg:shadow-lg lg:p-4">
                    {
                    commentShown === null ?
                    <>
                        <span className="font-semibold my-4">Annotations</span>
                        <div className="flex flex-col">
                            <Input.Search
                                size="middle" 
                                placeholder="Search"
                                allowClear 
                                onChange={onSearchChange}
                                onSearch={onSearch} 
                                className="p-1 text-sm shadow-sm w-full" 
                            />
                            <div className="mt-4 mb-2 flex font-semibold justify-end items-center">
                                <span className="text-sm">Sort:</span>
                                <Select
                                    defaultValue={DEFAULT_SORT_OPT}
                                    style={{width: 150}}
                                    onChange={onSortChange}
                                    size="small"
                                    className="ml-2"
                                >
                                    <Select.Option value="popular">Most Commented</Select.Option>
                                    <Select.Option value="latest">Latest</Select.Option>
                                    <Select.Option value="oldest">Oldest</Select.Option>
                                </Select>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                        {
                            annotations.map(ann => (<PostCard 
                                    key={ann.id}
                                    data={ann}
                                    comments={ann.comments}
                                    handleDelete={handleDelete}
                                    handleEdit={props.handleEditAnnotation}
                                    handleReply={handleReply}
                                    handleFlag={handleFlag}
                                    onClick={handleAnnotationClick}
                                    showComments={showComments}
                                />
                            ))
                        }
                        </div>

                    </>
                    :
                    <>
                        <div 
                            className="flex items-center text-sm font-medium cursor-pointer"
                            onClick={hideComments}
                        ><LeftOutlined className="mr-1 text-xs" /><span>Back</span></div>
                        <CommentPanel
                            data={annotations.filter(ann => ann.id === commentShown)[0]}
                            handleDelete={handleDelete}
                            handleTimeClick={handleAnnotationClick}
                            handleEditAnnotation={props.handleEditAnnotation}
                            handleReply={handleReplyComment}
                            handleDeleteComment={handleDeleteComment}
                            handleEditComment={props.handleEditComment}
                            handleFlag={handleFlag}
                        />
                    </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

/*
    playing={this.state.isVideoPlaying}
    playIcon={<button className="react-player__bc-btn-play">Play</button>}
    onProgress={this.onVideoProgress}
    onReady={this.onVideoReady}
*/

export default VideoAnnotationPanel